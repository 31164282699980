import React from "react"
import { graphql } from "gatsby"

import { Disqus, CommentCount } from "gatsby-plugin-disqus"

import Layout from "../../components/resuable/layout/layout"
import PostContainer from "../../components/page/blog-components/blog-post-page/post-container/post-container"
import PostHeading from "../../components/page/blog-components/blog-post-page/post-heading/post-heading"
import Share from "../../components/resuable/share/share"
import PostAuthor from "../../components/page/blog-components/blog-post-page/post-author/post-author"
import useWindowSize from "../../components/hooks/useWindowSize"

import BannerAd from "../../images/ads/200X200.jpg"
import PostRelated from "../../components/page/blog-components/blog-post-page/post-related/post-related"
import { useFetch } from "../../components/hooks/useFetch"
import { getRecommendedPosts } from "../../utils/helpers/blog-helpers"

const PostTemplate = ({ data, pageContext }) => {
  const {
    id,
    title,
    slug,
    content,
    acf,
    yoast_meta,
    author,
    featured_media,
    categories,
  } = data.wordpressPost

  console.log(categories[0].name)

  console.log(pageContext)

  const { posts } = data.allWordpressPost

  let disqusConfig = {
    url: `https://leadmirror.com/a2i-world/${slug}`,
    identifier: id,
    title: title,
  }

  const dynamicData = useFetch(
    `https://cms.leadmirror.com/wp-json/wp/v2/posts?per_page=3&30&search=${categories[0].name}`,
    {}
  )
  const { response: relatedPosts } = dynamicData

  // console.log(getRecommendedPosts(posts))
  // console.log(relatedPosts)

  const size = useWindowSize()

  return (
    <Layout theme="dark" isBlogMenuActive={size.width > 768}>
      <PostHeading
        title={title}
        yoast_meta={yoast_meta}
        content={content}
        subtitle={acf.subtitle}
        isTopPost={acf.isTopPost}
        featured_media={featured_media}
        author={author}
      />
      <PostContainer title={title}>
        {/* <a href="https://app.leadmirror.com/#/?sandbox=true" target="_blank" rel="noopener noreferrer">
          <div className="bannerad__side">
            <img src={BannerAd} alt="banner ad" />
          </div>
        </a> */}
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="container__content"
        />
        <PostRelated
          posts={getRecommendedPosts(posts)}
          relatedPosts={relatedPosts}
        />
        <PostAuthor author={author} />
        {/* <CommentCount config={disqusConfig} placeholder={'...'} /> */}
        <Disqus config={disqusConfig} />
      </PostContainer>
    </Layout>
  )
}

export default PostTemplate

// Getting GraphQL variable from context in creactePage (see gatsby-node.js)
export const query = graphql`
  query postQuery($slug: String!, $category0: Int!) {
    wordpressPost(slug: { eq: $slug }) {
      id
      slug
      title
      status
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        subtitle
        isTopPost
      }
      author {
        id
        slug
        name
        description
        avatar_urls {
          wordpress_96
        }
        mpp_avatar {
          wordpress_96
        }
      }
      featured_media {
        id
        source_url
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      categories {
        name
        slug
      }
      tags {
        name
        slug
      }
    }
    allWordpressPost(
      filter: {
        categories: { elemMatch: { wordpress_id: { eq: $category0 } } }
      }
      limit: 10
      sort: { order: DESC, fields: date }
    ) {
      posts: edges {
        node {
          id
          title
          slug
          categories {
            wordpress_id
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
