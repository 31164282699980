import React, { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiFacebookBox, mdiTwitterBox, mdiLinkedinBox, mdiWhatsapp } from '@mdi/js'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

import styled from 'styled-components'
import { dimensions } from '../../../utils/styles/styles'

const StyledShare = styled.div`
  position: fixed;
  max-width: ${dimensions.paraWidth};
  width: auto;
  margin: 20px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #dfdfdf;
  font-size: 16px;
  svg {
    width: 14px;
  }
  > * {
    cursor: pointer;
    display: inline;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const Share = (
  {title}
) => {
  const [shareUrl, setShareUrl] = useState('');
  useEffect(() => {
    setShareUrl(`https://leadmirror.com/${window.location.pathname}`)
  });
  console.log(title);
  return (
    <StyledShare>
      <FacebookShareButton
        quote={`Read "${title}" on the LeadMirror Blog #leadmirror #a2i`}
        url={`${shareUrl}`}
      >
        <Icon path={mdiFacebookBox}
          size={3}
          color="#3b5999"
        />
      </FacebookShareButton>
      <TwitterShareButton
        title={`Read "${title}" on the LeadMirror Blog #leadmirror #a2i`}
        url={`${shareUrl}`}
      >
        <Icon path={mdiTwitterBox}
          size={3}
          color="#55acee"
        />
      </TwitterShareButton>
      <LinkedinShareButton
        url={`${shareUrl}`}
      >
        <Icon path={mdiLinkedinBox}
          size={3}
          color="#0077B5"
        />
      </LinkedinShareButton>
      <WhatsappShareButton
        title={`Read "${title}" on the LeadMirror Blog #leadmirror #a2i`}
        url={`${shareUrl}`}
      >
        <Icon path={mdiWhatsapp}
          size={3}
          color="#25D366"
        />
      </WhatsappShareButton>
    </StyledShare>
  )
}

Share.defaultProps = {
  title: ''
}

export default Share
