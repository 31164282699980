import React from 'react'
import styled from 'styled-components'
import { colors, dimensions } from '../../../../../utils/styles/styles'
import { Link } from 'gatsby'

const StyledPostAuthor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 60px 0px 20px;
  div.container__heading {
    width: 100%;
    max-width: ${dimensions.paraWidth};
    h4 {
      text-align: left;
    }
  }
  div.container__main {
    width: 100%;
    max-width: ${dimensions.paraWidth};
    padding: 30px 20px;
    background: ${colors.darkGray};
    display: flex;
    border-radius: 5px;
    div.container__image {
      flex: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:first-child {
        margin-right: 30px;
      }
      img {
        margin-bottom: 10px;
        border-radius: 50%;
        border: 4px solid ${colors.priDark};
      }
      h5, span {
        text-align: center;
      }
      h5 {
        margin-bottom: 0px;
        color: ${colors.priDark};
      }
      span.designation {
        font-size: 12px;
      }
    }
    div.container__text {
      flex: 70%;
      p {
        font-size: 14px;
        font-style: italic;
      }
    } 
  }
  @media (max-width: 768px) {
    flex-direction: column;
    div.container__main {
      padding: 10px 20px;
      flex-direction: column;
      div.container__image {
        flex: 100%;
        &:first-child {
          margin-right: 0px;
        }
      }
      div.container__text {
        flex: 100%;
        margin-top: 14px;
      }
    }
  }
`

const PostAuthor = ({
  author
}) => {
  return (
    <StyledPostAuthor>
      <div className="container__heading">
        <h4>About the Author</h4>
      </div>
      <div className="container__main">
        <div className="container__image">
          <img src={author.mpp_avatar.wordpress_96} alt="Picture of the author" />
          <Link to={`/a2i-world/author/${author.slug}`}>
            <h5>{author.name}</h5>
          </Link>
          {/* <span className="designation">Senior UX Designer at Montaigne</span> */}
        </div>
        <div className="container__text">
          <p>{author.description}</p>
        </div>
      </div>
    </StyledPostAuthor>
  )
}

PostAuthor.defaultProps = {
  author: {
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia, quis voluptatem voluptas nesciunt sequi mollitia eum veniam tempore hic delectus aperiam dolorum totam! Praesentium quis, dolorem porro veritatis magnam voluptatibus expedita aspernatur ea eaque sint eius quibusdam eligendi exercitationem minima?`
  }
}

export default PostAuthor