import React from "react"
import styled from "styled-components"
import Section from "../../../../resuable/section/section"
import { dimensions, colors } from "../../../../../utils/styles/styles"
import BannerAd from "../../../../../images/ads/250X250.jpg"

import Share from "../../../../resuable/share/share"

const StyledPostContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 0 40px;
  display: flex;
  justify-content: center;
  div.bannerad__side {
    cursor: pointer;
    position: fixed;
    right: 40px;
    /* top: 50vh; */
    height: 240px;
    width: 240px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  div.container__sidebar--left {
    position: relative;
    flex: 25%;
  }
  div.container__main {
    flex: 50%;
    max-width: ${dimensions.paraWidth};
    div.container__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h2 {
        margin: 30px 0px 8px;
        font-size: 2.4rem;
      }
      h3 {
        margin: 24px 0px 8px;
        font-size: 2rem;
      }
      h4 {
        margin: 20px 0px 8px;
        font-size: 1.8rem;
      }
      p {
        margin: 20px 0px 8px;
        word-wrap: break-word;
      }
      ul {
        margin-top: 20px;
        margin-bottom: 0px;
      }
      figure {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
        figcaption {
          color: ${colors.lightGray};
        }
      }
      figure.wp-block-table {
        width: 100%;
        padding: 30px 0px 10px;
        color: ${props => props.theme.bodyColor};
        overflow-x: scroll;
        table {
          font-size: 14px;
          th,
          td {
            border-bottom: 1px solid ${props => props.theme.bodyColor};
          }
        }
      }
      pre.wp-block-code {
        width: 100%;
        padding: 20px;
        background: #282828;
        border: 1px solid ${colors.gray};
        code {
          max-width: 100%;
          display: inline-block;
          color: ${colors.lightGray};
          font-size: 14px;
          white-space: pre-wrap;
        }
      }
    }
  }
  div.container__sidebar--right {
    position: relative;
    flex: 25%;
    display: flex;
    justify-content: flex-start;
  }
  div#disqus_thread {
    width: 100%;
    max-width: ${dimensions.paraWidth};
  }
  @media (max-width: 1270px) {
    flex-direction: column;
    align-items: center;
    div.bannerad__side {
      display: none;
    }
    div.container__sidebar--left {
      flex: 100%;
      order: 2;
    }
    div.container__main {
      flex: 100%;
      order: 1;
    }
    div.container__sidebar--right {
      flex: 100%;
      order: 3;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    div.container__content {
    }
  }
`

const PostContainer = ({ title, ...props }) => {
  return (
    <Section padding="0px" fullwidth={true}>
      <StyledPostContainer>
        <div className="container__sidebar--left">
          
        </div>
        <div className="container__main">{props.children}</div>
        <div className="container__sidebar--right">
          {/* <a href="https://app.leadmirror.com/#/?sandbox=true" target="_blank" rel="noopener noreferrer" id="blog_ad">
            <div className="bannerad__side">
              <img src={BannerAd} alt="banner ad" />
            </div>
          </a> */}
          <Share title={title} />
        </div>
      </StyledPostContainer>
    </Section>
  )
}

PostContainer.defaultProps = {}

export default PostContainer
