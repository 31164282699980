import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import readingTime from "reading-time"

import { dimensions, fonts, colors } from "../../../../../utils/styles/styles"
import { Link } from "gatsby"
import SEO from "../../../../resuable/seo/seo"
import BlogViews from "../../../../resuable/blog/blog-views/blog-views"

const StyledPostHeading = styled.header`
  width: 100%;
  /* max-width: ${`900px`}; */
  padding: 4rem 0 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  div.hero__image {
    width: 100%;
    height: 80vh;
    margin-bottom: 40px;
    div.hero__image-img {
      width: 100%;
      height: 100%;
      background: purple;
    }
  }
  h1, h2 {
    width: 100%;
  }
  h1 {
    max-width: ${dimensions.paraWidth};
    font-size: 4rem;
  }
  h2 {
    max-width: ${dimensions.paraWidth};
    color: ${colors.gray};
    line-height: 160%;
    font-size: 2.4rem;
    font-weight: 400;
    font-family: ${fonts.sec};
  }
  div.meta {
    width: 100%;
    max-width: 650px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > * {
      margin-right: 20px;
      font-size: 12px;
    }
    div.container__author {
      max-width: ${dimensions.paraWidth};
      /* width: 100%; */
      flex: 1 1 30%;
      margin-bottom: 0px;
      display: flex;
      div.author__image {
        img {
          height: 40px;
          width: 40px;
          margin-right: 10px;
          border-radius: 50%;
          border: 2px solid ${colors.priDark};
        }
      }
      div.author__meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          display: inline-block;
        }
        span.name {
          font-size: 12px;
          font-weight: bold;
          text-decoration: underline;
        }
        span.readingtime {
          font-size: 10px;
        }
      }
    }
  }
  img.featured__media {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    padding: 6rem 0 0rem;
    div.hero__image {
      width: 100%;
      height: 60vh;
      div.hero__image-img {
        width: 100%;
        height: 100%;
        background: purple;
      }
    }
    h1 {
      max-width: 100%;
      font-size: 2.8rem;
    }
    h2 {
      font-size: 1.8rem;
    }
    img.featured__media {
      width: 100%;
      height: 300px;
      margin-bottom: 30px;
      object-fit: cover;
    }
  }
`

const PostHeading = ({
  title,
  yoast_meta,
  content,
  subtitle,
  featured_media,
  author,
}) => {
  return (
    <React.Fragment>
      <SEO
        title={`${title} | LeadMirror`}
        description={yoast_meta.yoast_wpseo_metadesc}
        image={featured_media !== null ? featured_media.source_url : ""}
      />
      <StyledPostHeading>
        <div className="hero__image">
          <Img
            fluid={featured_media.localFile.childImageSharp.fluid}
            className="hero__image-img"
          />
        </div>
        <h1>{title}</h1>
        <div className="meta">
          {/* <div className="container__author">
            <div className="author__image">
              <img src={author.mpp_avatar.wordpress_96} alt="Author Image" />
            </div>
            <div className="author__meta">
              <Link to={`/a2i-world/author/${author.slug}`}>
                <span className="name">{author.name}</span>
              </Link>
              <span className="readingtime">{readingTime(content).text}</span>
            </div>
          </div>
          <div className="container__date">
            <span>23rd August 2019</span>
          </div>
          <div className="container__viewcount">
            <BlogViews />
          </div> */}
          <span className="meta__author">Written by <Link to={`/a2i-world/author/${author.slug}`}>{author.name}</Link></span>
          <span>|</span>
          <span className="meta__readingtime">{readingTime(content).text}</span>
          <span>|</span>
          <BlogViews />
        </div>
        {subtitle !== null && <h2>{subtitle}</h2>}
      </StyledPostHeading>
    </React.Fragment>
  )
}

PostHeading.defaultProps = {
  title: "Lorem ipsum dolar set amet",
  subtitle:
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magni iste voluptas nulla harum rerum eius fugit veritatis libero! Repellat, deleniti.",
}

export default PostHeading
