import React, { useEffect } from "react"
import styled from "styled-components"

import Eyes from './../../../../assets/icons/Eyes.inline.svg'

const StyledBlogViews = styled.div`
  display: inline-flex;
  font-size: 12px;
  align-items: center;
  svg {
    margin-right: 4px;
  }
  span {
    font-size: 12px;
  }
`

const BlogViews = ({ count }) => {
  useEffect(() => {
    // If prev user
    // fetch view count data
    // If new user
    // increment view count data by one and fetch data
  }, [])
  return (
    <StyledBlogViews>
      {/* <Eyes /> */}
      <span>{count} Views</span>
    </StyledBlogViews>
  )
}

BlogViews.defaultProps = {
  count: 105,
}

export default BlogViews
