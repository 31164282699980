import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Row from "../../../../resuable/row/row"
import { fonts } from "../../../../../utils/styles/styles"
import { Link } from "gatsby"

const StyledPostRelated = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .container__heading {
    margin-top: 20px;
    margin-bottom: 12px;
    justify-content: flex-start;
    width: 100%;
    span {
      color: #fff;
      font-family: ${fonts.pri};
      font-weight: 700;
      font-size: 2.4rem;
      text-decoration: underline;
    }
  }
  .container__body {
    width: 100%;
    display: flex;
    .container__post {
      position: relative;
      height: 120px;
      flex: 1 1 30%;
      padding: 0px;
      display: inline-block;
      background: ${props => props.theme.backgroundAccent};
      &:not(:last-child) {
        margin-right: 20px;
      }
      /* img {
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        filter: brightness(20%);
      } */
      .container__post-image {
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        filter: brightness(20%);
      }
      .title {
        position: absolute;
        z-index: 2;
        color: #dfdfdf;
        font-size: 14px;
      }
    }
  }
`

const PostRelated = ({ posts, relatedPosts }) => {
  console.log(posts)
  if (!relatedPosts) {
    return <div>Loading...</div>
  }
  return (
    <StyledPostRelated>
      <Row className="container__heading">
        <span>People who read this also read:</span>
      </Row>
      <Row className="container__body">
        {posts.length !== null &&
          posts.map(post => (
            <Link to={`/a2i-world/${post.node.slug}`} className="container__post" key={post.node.id}>
              <Img
                fixed={post.node.featured_media.localFile.childImageSharp.fluid}
                className="container__post-image"
              />
              <h4 className="title">{post.node.title}</h4>
            </Link>
          ))}
      </Row>
    </StyledPostRelated>
  )
}

PostRelated.defaultProps = {}

export default PostRelated
